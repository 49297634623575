import(/* webpackMode: "eager" */ "/app/components/Heading/Heading.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Blogs/Blogs.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Contacts"] */ "/app/modules/Contacts/ui/Contacts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Features"] */ "/app/modules/Features/Features.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/News/News.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["PressMaterial"] */ "/app/modules/PressMaterial/PressMaterial.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/StoriesList/StoriesList.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prezly+theme-kit-core@9.8.2_@prezly+sdk@21.23.0/node_modules/@prezly/theme-kit-core/build/http/HttpClient.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prezly+theme-kit-nextjs@9.8.2_@playwright+test@1.51.1_@prezly+sdk@21.23.0_next@14.2.26_e8df54994d7d3b80fca39e0b7b8fc349/node_modules/@prezly/theme-kit-nextjs/build/adapters/routing/lib/RoutingAdapter.client.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prezly+theme-kit-nextjs@9.8.2_@playwright+test@1.51.1_@prezly+sdk@21.23.0_next@14.2.26_e8df54994d7d3b80fca39e0b7b8fc349/node_modules/@prezly/theme-kit-nextjs/build/adapters/theme-settings/client.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedDate"] */ "/app/node_modules/.pnpm/@prezly+theme-kit-react@9.8.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@prezly/theme-kit-react/build/intl/FormattedDate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedMessage"] */ "/app/node_modules/.pnpm/@prezly+theme-kit-react@9.8.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@prezly/theme-kit-react/build/intl/FormattedMessage.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedTime"] */ "/app/node_modules/.pnpm/@prezly+theme-kit-react@9.8.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@prezly/theme-kit-react/build/intl/FormattedTime.mjs");
